<template>
  <div
    :class="{
      'tooltip__wrapper': true,
      'tooltip__wrapper--inline': inline
    }"
  >
    <div
      ref="tooltip"
      :class="{
        'tooltip': true,
        [`tooltip--${position}`]: true,
        'tooltip--active': tooltipActive,
        [`tooltip--size--${size}`]: size,
      }"
      :style="computedTooltipStyles"
    >
      <slot name="content">
        <T state="secondary" type="body-1">
          {{ content }}
        </T>
      </slot>
    </div>
    <div
      v-click-outside="onOutsideClick"
      ref="trigger"
      class="tooltip__trigger"
      @click="onClick"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import T from '@/components/typography/T.vue';

export default {
  name: 'Tooltip',
  components: {
    T,
  },
  props: {
    content: String,
    position: {
      type: String,
      default: 'top',
    },
    offset: {
      type: Number,
      default: 12,
    },
    trigger: {
      type: String,
      default: 'hover',
    },
    size: {
      type: String,
      default: 'md',
    },
    inline: Boolean,
  },
  data: () => ({
    tooltipActive: false,
    positionsMap: [
      'top',
      'top-right',
      'right',
      'bottom-right',
      'bottom',
      'bottom-left',
      'left',
      'top-left',
    ],
    tooltipPosition: {
      top: 0,
      left: 0,
      right: 0,
      maxWidth: 870,
    },
    computedTooltipStyles: {},
  }),
  methods: {
    calculateTooltipBounds() {
      this.$nextTick(() => {
        this.computedTooltipStyles = {
          top: `${this.tooltipPosition.top}px`,
          ...(this.tooltipPosition.left ? {
            left: `${this.tooltipPosition.left}px`,
          } : {}),
          ...(this.tooltipPosition.right ? {
            right: `${this.tooltipPosition.right}px`,
          } : {}),
          'max-width': `${this.tooltipPosition.maxWidth}px`,
        };
      });
    },
    calculateTooltipPosition() {
      if (
        this.$refs.trigger
        && this.$refs.trigger.getBoundingClientRect()
        && this.$refs.tooltip
        && this.$refs.tooltip.getBoundingClientRect()
      ) {
        const triggerBounds = this.$refs.trigger.getBoundingClientRect();
        const tooltipBounds = this.$refs.tooltip.getBoundingClientRect();

        switch (this.position) {
          case 'top':
            this.tooltipPosition.top = triggerBounds.top - (tooltipBounds.height + this.offset);
            this.tooltipPosition.left = (
              triggerBounds.left + triggerBounds.width / 2
            ) - (tooltipBounds.width / 2);
            break;
          case 'top-right': {
            this.tooltipPosition.top = triggerBounds.top - (tooltipBounds.height + this.offset);
            this.tooltipPosition.right = 0;
            this.tooltipPosition.left = triggerBounds.left - this.offset;
            const freeSpace = window.innerWidth - (tooltipBounds.left + 80);
            this.tooltipPosition.maxWidth = freeSpace > 850 ? 850 : freeSpace;
            break;
          }
          case 'bottom-right': {
            this.tooltipPosition.top = triggerBounds.bottom + this.offset;
            this.tooltipPosition.right = 0;
            this.tooltipPosition.left = triggerBounds.left - this.offset;
            const freeSpace = window.innerWidth - (tooltipBounds.left + 80);
            this.tooltipPosition.maxWidth = freeSpace > 850 ? 850 : freeSpace;
            break;
          }
          case 'left': {
            this.tooltipPosition.top = triggerBounds.top - this.offset;
            this.tooltipPosition.right = (window.innerWidth - triggerBounds.left) + this.offset;
            this.tooltipPosition.left = 0;
            const freeSpace = window.innerWidth - (window.innerWidth - (tooltipBounds.right - 80));
            this.tooltipPosition.maxWidth = freeSpace > 850 ? 850 : freeSpace;
            break;
          }
          case 'bottom':
            this.tooltipPosition.top = triggerBounds.top + (tooltipBounds.height + this.offset);
            this.tooltipPosition.left = (
              triggerBounds.left + triggerBounds.width / 2
            ) - (tooltipBounds.width / 2);
            break;
          default:
            this.tooltipPosition.top = triggerBounds.top - (tooltipBounds.height + this.offset);
            this.tooltipPosition.left = (
              triggerBounds.left + triggerBounds.width / 2
            ) - (tooltipBounds.width / 2);
        }
        this.calculateTooltipBounds();
      }
    },
    onClick() {
      this.calculateTooltipPosition();
      if (this.trigger === 'click') {
        this.tooltipActive = !this.tooltipActive;
      }
    },
    onMouseEnter() {
      this.calculateTooltipPosition();
      if (this.trigger === 'hover') {
        this.tooltipActive = true;
      }
    },
    onMouseLeave() {
      this.calculateTooltipPosition();
      if (this.trigger === 'hover') {
        this.tooltipActive = false;
      }
    },
    onOutsideClick() {
      if (this.trigger === 'click') {
        this.tooltipActive = false;
      }
    },
  },
  mounted() {
    document.getElementById('tooltipLayer').appendChild(this.$refs.tooltip);
    window.addEventListener('resize', () => {
      this.calculateTooltipPosition();
      this.tooltipActive = false;
    });
    window.addEventListener('scroll', () => {
      if (!this.tooltipActive) return;
      this.calculateTooltipPosition();
    });
    window.addEventListener('orientationchange', this.calculateTooltipPosition);
  },
  beforeDestroy() {
    document.getElementById('tooltipLayer').removeChild(this.$refs.tooltip);
    window.removeEventListener('resize', this.calculateTooltipPosition);
    window.removeEventListener('scroll', this.calculateTooltipPosition);
    window.removeEventListener('orientationchange', this.calculateTooltipPosition);
  },
};
</script>

