<template>
  <component
    :is="isInline ? 'span' : 'div'"
    :class="{
      'typography': true,
      [`typography--type--${type}`]: !!type,
      [`typography--state--${state}`]: !!state,
      [`typography--font--${font}`]: !!font,
      [`typography--align--${align}`]: !!align,
      'typography--capitalize': !!capitalize,
      'typography--uppercase': !!uppercase,
      'typography--pre': pre,
      'typography--nowrap': noWrap,
      'typography--inline': isInline,
      'typography--line-through': lineThrough,
    }"
    @click="$emit('click', $event)"
  >
    <slot>
      {{ text }}
    </slot>
  </component>
</template>

<script>
export default {
  name: 'T',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    align: {
      type: String,
      default: 'left',
    },
    state: String,
    font: String,
    text: String,
    pre: Boolean,
    noWrap: Boolean,
    inline: Boolean,
    capitalize: Boolean,
    isInline: Boolean,
    uppercase: Boolean,
    lineThrough: Boolean,
  },
};
</script>

<style scoped lang="scss">
.typography {
  &--inline {
    display: inline;
  }
  &--pre {
    white-space: pre-line;
  }
  &--nowrap {
    white-space: nowrap;
  }
  &--type--heading-1, &--type--heading-2, &--type--heading-3 {
    @include additional-font;
  }
  &--type {
    @each $type, $h, $lh, $w, $ls in (
      ("heading-0", 36, 36, 600, 0),
      ("heading-1", 24, 30, 600, 0),
      ("heading-2", 20, 30, 600, 0),
      ("heading-3", 16, 24, 600, 0),
      ("button", 16, 24, 600, 0),
      ("button-2", 16, 24, 500, 0),
      ("body", 18, 28, 400, 0),
      ("body-1", 16, 24, 400, 0),
      ("body-2", 14, 16, 400, 0),
      ("body-2-tall", 14, 20, 400, 0),
      ("body-3", 12, 15, 400, 0),
      ("label", 12, 15, 700, 1),
      ("caption", 12, 16, 600, 0),
      ("counter", 11, 11, 700, 0),
      ("label-accept", 16, 20, 400, 0),
      ("voucher-discount", 32, 36, 600, 0),
    ) {
      &--#{$type} {
        font-size: rem($h + 0px);
        line-height: rem($lh + 0px);
        font-weight: $w;
        letter-spacing: rem($ls + 0px);
      }
    }
  }
  &--align {
    &--left {
      text-align: left;
    }
    &--center {
      text-align: center;
    }
    &--right {
      text-align: right;
    }
  }
  &--state {
    &--primary {
      color: var(--color-blue-1);
    }
    &--secondary {
      color: var(--color-general-3);
    }
    &--secondary-light {
      color: var(--color-general-5);
    }
    &--gray {
      color: var(--color-general-6);
    }
    &--bright {
      color: var(--color-general-2);
    }
    &--danger {
      color: var(--color-red);
    }
    &--success {
      color: var(--color-green);
    }
    &--info {
      color: var(--color-yellow-1);
    }
    &--white {
      color: var(--color-white);
    }
    &--input {
      height: rem(42px);
      border: 1px solid var(--color-general-6);
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 0 rem(16px);
      font-size: rem(16px);
      line-height: rem(24px);

    }
  }
  &--font {
    &--main {
      @include main-font;
    }
    &--additional {
      @include additional-font;
    }
  }
  &--capitalize {
    text-transform: capitalize;
  }
  &--uppercase {
    text-transform: uppercase;
  }
  &--line-through {
    text-decoration: line-through;
  }
}

</style>
