import { i18n } from '@/i18n';

const localesMap = {
  en: 'en-GB',
  ru: 'ru',
  de: 'de-DE',
  fr: 'fr-FR',
  es: 'eu_ES',
};

export const formatDate = (date, {
  withDate = true,
  withHours = false,
  withMinutes = false,
  withSeconds = false,
} = {}) => {
  let locale;

  try {
    locale = Intl.Collator.supportedLocalesOf(localesMap[i18n.locale]).join(',') || 'en-GB';
  } catch (e) {
    locale = 'en-GB';
  }
  const intl = new Intl.DateTimeFormat(locale, {
    ...(withDate ? {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    } : {}),
    ...(withHours ? {
      hour: '2-digit',
      hour12: false,
    } : {}),
    ...(withMinutes ? {
      minute: '2-digit',
    } : {}),
    ...(withSeconds ? {
      second: '2-digit',
    } : {}),
  });
  return intl.format(new Date(date));
};
