export function objectToFormData(data = {}) {
  if (data instanceof FormData) {
    return data;
  }
  if (typeof data === 'object' && Object.keys(data).length > 0) {
    const formData = new FormData();
    const payload = Object.entries(data);
    payload.forEach((val) => {
      formData.append(...val);
    });
    return formData;
  }
  return data;
}
