<template>
  <div
    :class="{
      'chat-message': true,
      'chat-message': true,
      'chat-message--you': !message.operator,
      'chat-message--operator': message.operator,
    }"
  >
    <div class="chat-message__body">
      <div class="chat-message__header" v-if="!isSame">
        <T type="button" class="chat-message__sender">
          {{message.sender}}
        </T>
        <T type="caption" class="chat-message__date">
          {{ message.created_at.replace(/-/g, '/')
          | formatDate({withHours: true, withMinutes: true})}}
        </T>
      </div>
      <div class="chat-message__content">
        <component
          v-if="isFile"
          :is="file ? 'a' : 'div'"
          :href="file ? file.url : undefined"
          :target="file ? '_blank' : undefined"
          :class="{
            'chat-message__file': true,
            'chat-message__file--error': errorWhileDownloadingFile,
          }"
        >
          <span
            :class="{
              'chat-message__file-icon': true,
              'chat-message__file-icon--loading': isLoadingFile,
            }"
          >
            <Icon
              :type="isLoadingFile ? 'loader' : (errorWhileDownloadingFile ? 'close' : 'file')"
              size="sm"
            />
          </span>
          <T type="caption" class="chat-message__file-error" v-if="errorWhileDownloadingFile">
            {{t('chat.file.error')}}
          </T>
          <span class="chat-message__file-info" v-else-if="!isLoadingFile">
            <T type="body-2-tall" class="chat-message__file-name">
              {{file && file.name}}
            </T>
            <T type="caption" class="chat-message__file-size">
              {{file && file.size}}
            </T>
          </span>
        </component>
        <T
          type="body-1"
          v-else
          class="chat-message__message"
          state="bright"
          v-html="unescape(escape(message.message).replace(/%0A/g, '<br/>'))"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from '@/hooks/useI18n';
import { computed, ref, watch } from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import Icon from '@/components/icon/Icon';
import T from '@/components/typography/T';

export default {
  name: 'ChatMessage',
  components: { T, Icon },
  props: {
    message: Object,
    isSame: Boolean,
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();

    const isFile = ref(!!+props.message.image);
    const file = ref();
    const isLoadingFile = ref(false);
    const errorWhileDownloadingFile = ref(false);

    watch(
      computed(() => props.message.image),
      async (image) => {
        if (!+image) {
          isFile.value = false;
          file.value = null;
          return;
        }
        errorWhileDownloadingFile.value = false;
        isFile.value = true;
        isLoadingFile.value = true;
        try {
          const { name, size, url } = await store.dispatch('chat/fetchFile', {
            session: props.message.session,
            fileId: props.message.id,
          });

          file.value = {
            name,
            url,
            size: `${Math.round(size / 1024)}kb`,
          };
        } catch (e) {
          errorWhileDownloadingFile.value = true;
        }
        isLoadingFile.value = false;
      }, {
        immediate: true,
      },
    );

    return {
      t,

      isFile,
      isLoadingFile,
      file,
      errorWhileDownloadingFile,
      escape,
      unescape,
    };
  },
};
</script>

<style lang="scss" scoped >
  .chat-message {
    $r: &;
    display: flex;

    &--you {
      #{$r}__content {
        background: var(--color-background-3);
        border-bottom-right-radius: 0;

        &::before {
          border-left: solid var(--color-background-3) rem(6px);
          left: 100%;
        }
      }
    }

    &--operator {
      #{$r}__content {
        background: var(--color-background-2);
        box-shadow: $box-shadow-primary;
        border-bottom-left-radius: 0;

        &::before {
          border-right: solid var(--color-background-2) rem(6px);
          right: 100%;
        }
      }
      #{$r}__sender {
        color: var(--color-blue-1);
      }
    }

    &__body {
      width: 100%;
    }

    &__header {
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin-bottom: rem(8px);
      padding: 0 rem(16px);
    }

    &__sender {
      color: var(--color-general-2);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__date {
      color: var(--color-general-4);
      margin-left: rem(12px);
    }

    &__content {
      position: relative;
      border-radius: rem(5px);

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        height: 0;
        width: 0;
        display: block;
        border-top: solid transparent rem(20px);
      }
    }

    &__message {
      padding: rem(16px);
      //color: var(--color-general-10);
    }

    &__file {
      display: flex;
      align-items: center;
      padding: rem(16px);

      &:not(&--error) {
        cursor: pointer;
      }

      &-icon {
        color: var(--color-general-3);
        background: var(--color-general-11);
        height: rem(36px);
        width: rem(36px);
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &--loading {
          animation: chat-file-icon-rotate 1s linear infinite;
        }

        @keyframes chat-file-icon-rotate {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }

      &-info {
        margin-left: rem(10px);
      }

      &-name {
        color: var(--color-general-10);
      }

      &-size {
        color: var(--color-general-4);
      }

      &-error {
        margin-left: rem(10px);
        color: var(--color-red);
      }
    }
  }
</style>
