import { computed, ref } from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';

export const useChat = () => {
  const store = useStore();

  const isChatVisible = computed({
    get() {
      return store.getters['chat/isVisible'];
    },
    set(isVisible) {
      store.dispatch('chat/toggleChat', isVisible);
    },
  });

  const toggleChat = () => {
    isChatVisible.value = !isChatVisible.value;
  };

  const isVolumeOn = computed({
    get() {
      return store.getters['chat/isVolumeOn'];
    },
    set(isOn) {
      store.dispatch('chat/toggleVolume', isOn);
    },
  });

  const toggleVolume = () => {
    isVolumeOn.value = !isVolumeOn.value;
  };

  const isClosingChat = ref(false);

  const closeChat = async () => {
    isClosingChat.value = true;
    try {
      await store.dispatch('chat/close');
      isChatVisible.value = false;
    } finally {
      isClosingChat.value = false;
    }
  };

  const agent = computed(() => store.getters['chat/agent']);

  const isRated = computed(() => store.getters['chat/isRated']);
  const rate = async (value) => {
    await store.dispatch('chat/rate', value);
  };

  const isChatExists = computed(() => store.getters['chat/isChatExists']);

  const isAgentAssigned = computed(() => !!agent.value);

  const isChatLoading = computed(() => store.getters['chat/isLoading']);

  const isSendingMessage = computed(() => store.getters['chat/isSendingMessage']);
  const sendMessage = (message) => {
    store.dispatch('chat/sendMessage', message);
  };

  const isSendingFile = computed(() => store.getters['chat/isSendingFile']);
  const sendFile = (file) => {
    store.dispatch('chat/sendFile', file);
  };

  const messages = computed(() => store.getters['chat/messages']);

  return {
    isChatVisible,
    toggleChat,

    isVolumeOn,
    toggleVolume,

    isClosingChat,
    closeChat,

    agent,
    isAgentAssigned,

    isChatLoading,

    isSendingMessage,
    sendMessage,

    isSendingFile,
    sendFile,

    isChatExists,

    messages,

    isRated,
    rate,
  };
};
