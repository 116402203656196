<template>
  <Icon
    :class="[
      'loader',
      !!state && [`loader--state--${state}`]
    ].filter(Boolean)"
    type="loader"
    :size="iconSize"
  />
</template>

<script>
import Icon from '@/components/icon/Icon.vue';

export default {
  name: 'Loader',
  components: {
    Icon,
  },
  props: {
    state: String,
    iconSize: {
      type: String,
      default: 'sm',
    },
  },
};
</script>

<style scoped lang="scss">
.loader {
  @keyframes button-loader-rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  animation: button-loader-rotate 1s linear infinite;
  &--state {
    &--primary {
      color: var(--color-general-2);
    }
  }
}
</style>
