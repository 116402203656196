<template>
  <form @submit.prevent="onSubmit" class="chat-form">
    <Tooltip
      size="sm"
      position="top"
      trigger="hover"
    >
      <template #content>
        <T state="secondary" type="body-1">
          {{ $t('chat.attach-a-file') }}
        </T>
      </template>
      <Button
        class="chat-form__file-control"
        state="transparent"
        :is-loading="isSendingFile"
        :is-disabled="isChatLoading"
        icon-before="clip"
        is-square
        size="sm"
        @click="selectFile"
      />
    </Tooltip>
    <InputText
      class="chat-form__field"
      is-content-editable
      type="textarea"
      v-model="message"
      state="primary"
      size="sm"
      :is-disabled="isSendingMessage || isChatLoading"
      :placeholder="$t('chat.placeholder')"
    />
    <Tooltip
      size="sm"
      position="top"
      trigger="hover"
    >
      <template #content>
        <T state="secondary" type="body-1" align="center">
          {{ $t('chat.send-message') }}
        </T>
      </template>
      <Button
        class="chat-form__submit"
        icon-before="send"
        is-square
        size="sm"
        state="primary"
        icon-size="sm"
        :is-loading="isSendingMessage"
        :is-disabled="isChatLoading"
        type="submit"
      />
    </Tooltip>
  </form>
</template>

<script>
import { useChat } from '@/hooks/useChat';
import { ref } from '@vue/composition-api';
import Button from '@/components/button/Button';
import InputText from '@/components/inputs/inputText/InputText';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import T from '@/components/typography/T.vue';
import { useI18n } from '@/hooks/useI18n';

export default {
  name: 'ChatForm',
  components: {
    T,
    Button,
    Tooltip,
    InputText,
  },
  setup() {
    const {
      isSendingMessage,
      sendMessage,

      isSendingFile,
      sendFile,

      isChatLoading,
    } = useChat();
    const { t } = useI18n();

    const message = ref('');

    const selectFile = () => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = ['image/png', 'image/jpeg', 'application/pdf'].join(',');

      input.addEventListener('change', (e) => {
        const file = e.target.files[0];
        if (file) {
          if (file.size > 500 * 1024) {
            alert(t('chat.fileUpload.tooLarge', {
              size: '500kb',
            }));
          } else {
            sendFile(file);
          }
        }
      });

      input.addEventListener('error', () => {
        input.remove();
      });

      input.click();
    };

    const onSubmit = async (e) => {
      e.preventDefault();
      const msgText = message.value;
      message.value = '';
      await sendMessage(msgText);
    };

    return {
      message,

      isSendingMessage,
      onSubmit,

      isSendingFile,
      selectFile,

      isChatLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
  .chat-form {
    display: flex;
    align-items: flex-start;

    &__file-control {
      &.button {
        padding-left: 0;
        padding-right: 0;
      }
      flex-shrink: 0;
    }

    &__field {
      margin-left: rem(8px);
      flex-grow: 1;
      flex-shrink: 1;
      word-break: break-word;
      max-height: rem(200px);
      min-height: rem(34px);
      overflow: hidden;

      display: flex;
      flex-direction: column;

      .field {
        height: 100%;
        overflow-y: auto;
        min-height: rem(34px);
      }
    }

    &__submit {
      margin-left: rem(8px);
    }
  }
</style>
