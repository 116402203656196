<template>
  <div
    :class="{
      'chat-header': true,
      'chat-header--with-agent': isAgentAssigned,
    }"
  >
    <div class="chat-header__title">
      <ChatAgent class="chat__agent chat-header__agent" v-if="isAgentAssigned"/>
      <div class="chat-header__placeholder" v-else>
        <Icon type="chat" class="chat-header__placeholder-icon"/>
        <div class="chat-header__placeholder-label">
          <T type="heading-2">
            {{$t('chat.support')}}
          </T>
        </div>
      </div>
    </div>
    <div class="chat-header__side">
      <ChatControls class="chat__controls"/>
    </div>
  </div>
</template>

<script>
import ChatAgent from '@/components/chat/agent/ChatAgent';
import Icon from '@/components/icon/Icon';
import T from '@/components/typography/T';
import ChatControls from '@/components/chat/controls/ChatControls';
import { useChat } from '@/hooks/useChat';

export default {
  name: 'ChatHeader',
  components: {
    ChatControls, T, Icon, ChatAgent,
  },
  setup() {
    const { isAgentAssigned } = useChat();

    return {
      isAgentAssigned,
    };
  },
};
</script>

<style lang="scss"  scoped >
  .chat-header {
    display: flex;
    align-items: flex-start;
    height: rem(52px);
    padding: 0 rem(16px);
    background: var(--color-background-2);
    box-shadow: $box-shadow-primary;
    position: relative;
    z-index: 1;

    &__side {
      margin-left: auto;
      padding-top: rem(14px);
      flex-shrink: 0;
    }

    &__title {
      margin-right: rem(10px);
      overflow: hidden;
      flex-grow: 1;
    }

    &__agent {
      padding-top: rem(12px);
      flex-grow: 1;
    }

    &__placeholder {
      display: flex;
      align-items: center;
      padding-top: rem(12px);

      color: var(--color-general-2);

      &-icon {
        height: rem(24px);
        width: rem(24px);
        flex-shrink: 0;
      }

      &-label {
        margin-left: rem(4px);
      }
    }

    &--with-agent {
      height: rem(82px);
    }
  }
</style>
