<template>
  <div class="chat-placeholder">
    <div class="chat-placeholder__title">
      <T type="heading-2">
        {{$t('chat.noChat.title', {name: username})}}
      </T>
    </div>
    <div class="chat-placeholder__sub-title">
      <T type="body-1">
        {{$t('chat.noChat.subTitle')}}
      </T>
    </div>
  </div>
</template>

<script>
import T from '@/components/typography/T';
import { useStore } from '@/hooks/useStore';
import { computed } from '@vue/composition-api';

export default {
  name: 'ChatPlaceholder',
  components: { T },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters['session/currentUser']);
    const username = computed(() => [user.value?.first_name, user.value?.last_name].filter(Boolean).join(' '));

    return {
      username,
    };
  },
};
</script>

<style lang="scss" scoped>
  .chat-placeholder {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

    &__title {
      color: var(--color-general-2);
    }

    &__sub-title {
      margin-top: rem(4px);
      color: var(--color-general-3)
    }
  }
</style>
