const state = () => ({
  isVolumeOn: false,
  isVisible: false,

  chat: null,

  isLoading: false,

  isSendingMessage: false,
  isSendingFile: false,
  openSession: null,
});

const getters = {
  isVolumeOn: (s) => s.isVolumeOn,
  isVisible: (s) => s.isVisible,
  openSession: (s) => s.openSession,

  chat: (s) => s.chat,

  isLoading: (s) => s.isLoading,

  isSendingMessage: (s) => s.isSendingMessage,
  isSendingFile: (s) => s.isSendingFile,

  isChatExists: (s) => !!s.chat,

  messages: (s) => s.chat?.messages || [],

  agent: (s) => {
    const messages = [...s.chat?.messages || []];
    messages.reverse();
    const agentMessage = messages.find(({ operator, system }) => !system & operator);

    return agentMessage ? {
      name: agentMessage.sender,
      avatar: `/img/avatars/${agentMessage.sender.replace(/\s/g, '').toLowerCase()}.png`,
      isTyping: s.chat.typing,
    } : null;
  },

  isRated: (s) => s.chat?.rated,
};

const mutations = {
  setVolume: (s, isOn) => {
    s.isVolumeOn = isOn;
  },
  setOpenSession: (s, openSession) => {
    s.openSession = openSession;
  },
  setVisibility: (s, isVisible) => {
    s.isVisible = isVisible;
  },
  setChat: (s, chat) => {
    s.chat = (chat && ('active' in chat)) ? chat : null;
  },
  setLoading: (s, isLoading) => {
    s.isLoading = isLoading;
  },
  setSendingMessage: (s, isSending) => {
    s.isSendingMessage = isSending;
  },
  setSendingFile: (s, isSending) => {
    s.isSendingFile = isSending;
  },
};

const actions = {
  toggleVolume: ({ commit }, value) => {
    commit('setVolume', value);
  },
  toggleChat: ({ commit }, value) => {
    commit('setVisibility', value);
  },

  async fetchChat({ commit, getters: localGetters }) {
    if (!localGetters.chat) {
      commit('setLoading', true);
    }
    try {
      const chat = await this.$axios.get('/livechat/live');
      commit('setChat', {
        ...chat,
        messages: chat?.messages || [],
      });
    } finally {
      commit('setLoading', false);
    }

    return true;
  },
  async setSession({ commit }, session) {
    return this.$axios.get(`/livechat/setsession/${session}`);
  },
  async sendMessage({ commit, getters: localGetters, rootGetters }, message) {
    commit('setSendingMessage', true);
    try {
      if (!localGetters.isChatExists) {
        const data = {
          act: 'reply',
          name: '',
          email: '',
          subject: '',
          referer: document.referrer || location.href,
        };

        if (rootGetters['session/isAuthorized'] && rootGetters['session/currentUser']) {
          const currentUser = rootGetters['session/currentUser'];
          data.email = currentUser.email_address;
          data.name = `${currentUser.first_name} ${currentUser.last_name}`;
        }

        await this.$axios.post('/livechat/live', data);
        if (window.ga) {
          console.log('send-event-chat');
          window.ga('send', {
            hitType: 'event',
            eventCategory: 'newuser',
            eventAction: 'chat',
          });
        }
      }
      const chat = await this.$axios.post('/livechat/live', {
        act: 'reply',
        message,
      });
      commit('setChat', {
        ...chat,
        messages: chat?.messages || [],
      });
    } finally {
      commit('setSendingMessage', false);
    }

    return true;
  },

  async sendFile({ commit, dispatch }, file) {
    commit('setSendingFile', true);
    try {
      const formData = new FormData();
      formData.append('files', file);
      await this.$axios.post('/livechat/upload', formData);
      await dispatch('fetchChat');
    } finally {
      commit('setSendingFile', false);
    }

    return true;
  },

  async fetchFile(context, {
    session,
    fileId,
  }) {
    const { filename, file, mime } = await this.$axios.get(`/livechat/${session}/download/${fileId}`);

    const blob = await (await fetch(`data:${mime};base64,${file}`)).blob();

    const url = URL.createObjectURL(blob);

    return {
      url,
      name: filename,
      size: blob.size,
    };
  },

  async rate({ dispatch }, value) {
    await this.$axios.post('/livechat/live', {
      act: 'rate',
      rate: value,
    });

    await dispatch('fetchChat');
  },

  async close() {
    await this.$axios.post('/livechat/live', {
      act: 'close',
    });
  },
};

export const ChatStorePlugin = (store) => {
  store.watch((watchState) => watchState.chat.isVisible, (value) => {
    if (value) {
      store.dispatch('chat/fetchChat');
    }
  }, {
    immediate: true,
  });

  let fetchTimeout;

  store.watch((watchState) => watchState.chat.chat?.active, (value) => {
    clearTimeout(fetchTimeout);
    if (+value) {
      fetchTimeout = setInterval(() => {
        store.dispatch('chat/fetchChat');
      }, 2000);
    }
  }, {
    immediate: true,
  });

  const audio = document.createElement('audio');
  audio.src = require('@/assets/sounds/chat.wav');
  audio.style.display = 'none';
  document.body.appendChild(audio);

  store.watch(
    (watchState) => watchState.chat.chat?.messages[watchState.chat.chat?.messages.length - 1].id,
    (value, oldValue) => {
      if (!store.getters['chat/isVolumeOn']) {
        return;
      }
      if ((value > oldValue) && (oldValue > 0)) {
        audio.pause();
        audio.currentTime = 0;
        audio.play();
      }
    },
  );
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
