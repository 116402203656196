import {
  getFromStorage, removeFromStorage, setToStorage, setToSessionStorage, removeFromSessionStorage, removeFromCookie, setToSessionCookie,
} from '@/utils/storageHelpers';
import { i18n } from '@/i18n';
import { getFromCookie } from '../../utils/storageHelpers';

const cookie = require('cookie');

export default {
  namespaced: true,
  state: {
    authorized: false,
    authorizedSubuser: !!getFromCookie('order_id'),
    user: null,
    settings: null,
    summary: null,
    invoiceCounters: null,
    ordersCounters: null,
    ordersTotalActive: null,
    recurringPaymentsCounters: {},
    ordersTotal: null,
    cartCount: null,
    notificationsCount: null,
    ticketsCounters: null,
    two_fa_enabled: null,
    unreadEmails: null,
    site: { },
    reviewAvailable: false,
    isReviewNotificationVisible: false,
  },
  mutations: {
    setAuthorize(state, authorized = true) {
      state.authorized = authorized;
    },
    setAuthorizeSubuser(state, authorized = true) {
      state.authorizedSubuser = authorized;
    },
    setReviewAvailable(state, value) {
      state.reviewAvailable = value;
    },
    setReviewNotificationVisibility(state, value) {
      state.isReviewNotificationVisible = value;
    },
    setUser(state, user) {
      state.user = user;
    },
    removeUser(state) {
      state.authorized = false;
      state.authorizedSubuser = false;
      state.user = null;
      state.reviewAvailable = false;
      state.isReviewNotificationVisible = false;
    },
    setSettings(state, settings) {
      state.settings = settings;
    },
    setSite(state, site) {
      state.site = site;
    },
    setSummary(state, summary) {
      state.summary = summary;
    },
    setInvoiceCounters(state, invoiceCounters) {
      state.invoiceCounters = invoiceCounters;
    },
    setRecurringPaymentsCounters(state, recurringPaymentsCounters) {
      state.recurringPaymentsCounters = recurringPaymentsCounters;
    },
    setOrdersCounters(state, ordersCounters) {
      state.ordersCounters = ordersCounters;
    },
    setOrdersTotalActive(state, ordersTotalActive) {
      state.ordersTotalActive = ordersTotalActive;
    },
    setNotificationsCount(state, notificationsCount) {
      state.notificationsCount = notificationsCount;
    },
    setOrdersTotal(state, ordersTotal) {
      state.ordersTotal = ordersTotal;
    },
    setCartCount(state, cartCount) {
      state.cartCount = cartCount;
    },
    setTicketsCounters(state, val) {
      state.ticketsCounters = val;
    },
    setTwoFaEnabled(state, val) {
      state.two_fa_enabled = val;
    },
    setUnreadEmails(state, count) {
      state.unreadEmails = count;
    },
  },
  actions: {
    async authorize({ commit }, authData = null) {
      if (!authData) {
        const rememberMe = getFromStorage('remember_me');
        if (!rememberMe) {
          return Promise.reject();
        }
        try {
          const res = await this.$axios.post('/user/authorize', {
            remember_me: rememberMe,
          });
          removeFromCookie('order_id');
          setToSessionCookie('is_authorized', true);
          if (res.subuser?.order_id) {
            setToSessionCookie('order_id', res.subuser.order_id);
            commit('setAuthorizeSubuser', true);
          } else {
            commit('setAuthorize', true);
            removeFromCookie('order_id');
          }

          return Promise.resolve(res);
        } catch (err) {
          commit('setAuthorize', false);
          commit('setAuthorizeSubuser', false);
          removeFromStorage('remember_me');
          return Promise.reject(err);
        }
      }
      try {
        const res = await this.$axios.post('/user/authorize', authData);
        if (res.remember_me) {
          setToStorage('remember_me', res.remember_me);
        }
        setToSessionCookie('is_authorized', true);
        if (res.subuser?.order_id) {
          setToSessionCookie('order_id', res.subuser.order_id);
          commit('setAuthorizeSubuser', true);
        } else {
          commit('setAuthorize', true);
          removeFromCookie('order_id');
        }
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async checkTwoFa(context, data) {
      try {
        const response = await this.$axios.post('/user/check_two_fa', data);
        return Promise.resolve(response.need_two_fa);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async register(context, regData = null) {
      if (!regData) {
        try {
          const response = await this.$axios.post('/user/register', { get_csrf: 1 });
          return Promise.resolve(response._csrf_token);
        } catch (err) {
          return Promise.reject(err);
        }
      }
      try {
        regData.cookie_params = '';
        if (document.cookie) {
          regData.cookie_params = btoa(JSON.stringify(cookie.parse(document.cookie)));
        }
        await this.$axios.post('/user/register', regData);
        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async getProfile({ commit, dispatch }) {
      try {
        const res = await this.$axios.get('/user/profile');
        commit('setInvoiceCounters', res.invoices);
        if ('recur_counts' in res) {
          commit('setRecurringPaymentsCounters', res.recur_counts);
        }
        commit('setOrdersCounters', res.orders_count);
        commit('setTicketsCounters', res.profile.tickets_answered);
        commit('setTwoFaEnabled', res.profile.two_fa_enabled);
        commit('setUser', res.profile);
        commit('setReviewAvailable', res.available_review);
        commit('setReviewNotificationVisibility', res.need_show_notification_review);
        commit('setSettings', res.settings);
        commit('setSite', res.site);
        commit('setSummary', res.summary);
        commit('setAuthorize');
        commit('setUnreadEmails', res.unread_emails);
        if (res.profile.culture) {
          await dispatch('app/setAppLocale', res.profile.culture, { root: true });
        }
        return Promise.resolve(res.profile);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async setLocale({ dispatch }, locale) {
      try {
        await this.$axios.get(`/user/language/${locale}`);
        await dispatch('getProfile');
        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async setProfile({ commit }, user) {
      commit('setUser', user);
    },
    async logout({ commit }) {
      try {
        await this.$axios.get('/user/logout');
        removeFromStorage('remember_me');
        removeFromCookie('is_authorized');
        removeFromStorage('selectedRates');
        removeFromCookie('order_id');
        window.localStorage.setItem('logout', Math.random());
        commit('removeUser');
        return Promise.resolve();
      } catch {
        return Promise.reject();
      }
    },
    removeSession({ commit }) {
      removeFromStorage('remember_me');
      removeFromCookie('is_authorized');
      removeFromStorage('selectedRates');
      removeFromCookie('order_id');
      commit('removeUser');
    },
    async approve(context, token) {
      try {
        const res = await this.$axios.post('/user/approve', { token });
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async unsubscribe(context, token) {
      try {
        const res = await this.$axios.post(`/user/unsubscribe/${token}`);
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async getSummary({ commit, state }) {
      try {
        const res = await this.$axios.get('/user/summary');
        if (res.counts) {
          commit('setInvoiceCounters', res.counts);
        }
        if ('balance' in res) {
          commit('setUser', { ...(state.user || {}), balance: res.balance });
        }
        if ('orders' in res) {
          commit('setOrdersCounters', {
            ...res.orders,
            all: res.orders_total,
          });
        }
        commit('setOrdersTotalActive', res.orders_total_active);
        commit('setOrdersTotal', res.orders_total);
        commit('setCartCount', res.cart);
        commit('setNotificationsCount', res.tickets);
        commit('setUnreadEmails', res.unread_emails);

        if ('rates' in res) {
          commit('invoices/setCurrencyRates', res.rates.currencies, { root: true });
        }

        if ('recur_counts' in res) {
          commit('setRecurringPaymentsCounters', res.recur_counts);
        }

        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async approveRecovery(context, token) {
      if (!token) {
        return Promise.reject();
      }
      try {
        const res = await this.$axios.get(`/user/password/approve/${token}`);
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async recoverPassword(context, model) {
      if (!model) {
        try {
          const token = await this.$axios.post('/user/password/recovery', { get_csrf: 1 });
          return Promise.resolve(token);
        } catch (err) {
          return Promise.reject(err);
        }
      }
      try {
        await this.$axios.post('/user/password/recovery', model);
        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async updateProfile(context, model) {
      try {
        const res = await this.$axios.post('/user/profile/update', model);
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async updateSettings({ dispatch }, model) {
      try {
        const res = await this.$axios.post('/user/settings/update', model);
        await dispatch('getProfile');
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async getPersonalData() {
      try {
        const res = await this.$axios.post('/user/personal_data');
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async deletePersonalData() {
      try {
        const res = await this.$axios.get('/user/personal_data_delete');
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async verifyPhoneStep1() {
      try {
        const res = await this.$axios.get('/user/verify_phone?verify=1');
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async verifyPhoneStep2(context, payload) {
      try {
        const res = await this.$axios.post('/user/verify_phone', payload);
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    saveSettings({ state, commit }, settings) {
      commit('setSettings', {
        ...state.settings,
        ...settings,
      });
    },
    async getTwoFaSecret() {
      try {
        const res = await this.$axios.get('/user/profile/two_fa_qr');
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async twoFaConfirm(context, { code }) {
      try {
        const res = await this.$axios.post('/user/profile/two_fa_confirm', { code });
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async twoFaDisable(context, { code }) {
      try {
        const res = await this.$axios.post('/user/profile/two_fa_disable', { code });
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async fetchRateUsInterview(context, from) {
      try {
        const res = this.$axios.get('/interview', {
          params: {
            from,
          },
        });

        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async submitInterview({ getters }, model) {
      return this.$axios.post('/interview', {
        'review[anonymous]': model.postAnonymously,
        'review[rating_service]': model.service,
        'review[rating_support]': model.support,
        'review[rating_assortment]': model.assortment,
        'review[author_name]': getters.currentUser.first_name,
        'review[text]': model.text,
        'review[allow_public]': model.allowPost,
      });
    },
  },
  getters: {
    isAuthorized: (state) => state.authorized,
    isAuthorizedSubuser: (state) => state.authorizedSubuser,
    currentUser: (state) => state.user,
    variables: (state) => state.variables,
    settings: (state) => state.settings,
    site: (state) => state.site,
    summary: (state) => state.summary,
    invoiceCounters: (state) => state.invoiceCounters,
    recurringPaymentsCounters: (state) => state.recurringPaymentsCounters,
    ordersCounters: (state) => state.ordersCounters,
    ordersTotalActive: (state) => state.ordersTotalActive,
    ticketsCounters: (state) => state.ticketsCounters,
    twoFaEnabled: (state) => state.two_fa_enabled,
    userCurrency: (state, getters, rootState, rootGetters) => {
      const userAuthorized = getters.isAuthorized;
      if (!userAuthorized) return null;
      const currencyRates = rootGetters['invoices/currencyRates'];
      const userCurrencyId = getters.settings?.currency;
      return userAuthorized && currencyRates
        ? currencyRates.find((cr) => cr.id === userCurrencyId)
        : null;
    },
    isVatNeeded: (state) => state.user && state.user.is_need_vat,
    vatAmount: (state) => state.user && state.user.site_vat,
    cartCount: (state) => state.cartCount,
    ordersTotal: (state) => state.ordersTotal,
    notificationsCount: (state) => state.notificationsCount,
    unreadEmails: (state) => state.unreadEmails,
    isReviewAvailable: (state) => !!state.reviewAvailable,
    isReviewNotificationVisible: (state) => !!state.isReviewNotificationVisible,
  },
};

