import { render, staticRenderFns } from "./ChatMessages.vue?vue&type=template&id=80a91212&scoped=true&"
import script from "./ChatMessages.vue?vue&type=script&lang=js&"
export * from "./ChatMessages.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./ChatMessages.vue?vue&type=style&index=0&id=80a91212&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "80a91212",
  null
  ,true
)

export default component.exports