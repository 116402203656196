import Cookie from 'js-cookie';

import { objectToFormData } from '@/utils/formData';
import { getFromStorage, setToStorage } from '@/utils/storageHelpers';
import Vuex from '@/store';

const domain = location.hostname.replace(/^member\./, '');

export const requestOnResolve = (config) => {
  if (config.data) {
    config.data = objectToFormData(config.data);
  }
  const sessionToken = Cookie.get('session') || getFromStorage('session-token');
  if (sessionToken) {
    config.headers = {
      ...config.headers,
      'Session-Token': sessionToken,
    };
  }
  return config;
};

export const requestOnReject = (error) => Promise.reject(error);

export const responseOnResolve = (response) => {
  const responseToken = response.headers['session-token'] || response.headers['Session-Token'];
  if (responseToken) {
    Cookie.set('session', responseToken, {
      path: '/',
      domain,
    });
    setToStorage('session-token', responseToken);
  }
  let isSuccess = true;
  let { data } = response;

  if ((typeof response.data) === 'object') {
    if (!response.data.result) {
      isSuccess = false;
    }

    if (response.data.data) {
      data = response.data.data;
    }
  }

  return isSuccess
    ? Promise.resolve(data)
    : Promise.reject(data);
};

export const responseOnReject = async (error) => {
  const responseToken = error.response && (error.response.headers['session-token'] || error.response.headers['Session-Token']);
  const localToken = getFromStorage('session-token');
  if (responseToken && responseToken !== localToken) {
    setToStorage('session-token', responseToken);
    Cookie.set('session', responseToken, {
      path: '/',
      domain,
    });
  }

  if (error.response && error.response.status > 250) {
    await Vuex.dispatch('app/throw404Error');
  }
  return Promise.reject(error.response && error.response.data ? error.response.data : null);
};
