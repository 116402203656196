var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat-controls"},[_c('Tooltip',{attrs:{"size":"sm","position":"top","trigger":"hover"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('T',{attrs:{"state":"secondary","type":"body-1"}},[_vm._v(" "+_vm._s(_vm.isVolumeOn ? _vm.$t('chat.mute') : _vm.$t('chat.unmute'))+" ")])]},proxy:true}])},[_c('Button',{class:{
        'chat-controls__control': true,
        'chat-controls__control--active': _vm.isVolumeOn,
      },attrs:{"icon-before":_vm.isVolumeOn ? 'volume-on' : 'volume-off',"state":"default","is-square":""},on:{"click":_vm.toggleVolume}})],1),(_vm.isChatExists)?_c('Tooltip',{attrs:{"size":"sm","position":"top","trigger":"hover"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('T',{attrs:{"state":"secondary","type":"body-1"}},[_vm._v(" "+_vm._s(_vm.$t('modal.close'))+" ")])]},proxy:true}],null,false,4139402203)},[_c('Button',{class:{
        'chat-controls__control': true,
        'chat-controls__control--active': _vm.isVolumeOn,
      },attrs:{"icon-before":"close","state":"default","is-square":"","is-loading":_vm.isClosingChat},on:{"click":_vm.closeChat}})],1):_vm._e(),_c('Tooltip',{attrs:{"size":"sm","position":"top","trigger":"hover"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('T',{attrs:{"state":"secondary","type":"body-1"}},[_vm._v(" "+_vm._s(_vm.$t('chat.minimize'))+" ")])]},proxy:true}])},[_c('Button',{class:{
        'chat-controls__control': true,
        'chat-controls__control--toggle': true,
        'chat-controls__control--active': _vm.isChatVisible,
      },attrs:{"icon-before":"down","state":"default","is-square":""},on:{"click":_vm.toggleChat}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }