<template>
  <component
    v-bind="computedAttributes"
    :class="{
      'button': true,
      [`button--size--${size}`]: !!size,
      ...states.reduce((acc, stateVal) => ({
        ...acc,
        [`button--state--${stateVal}`]: true,
      }), {}),
      'button--disabled': isDisabled,
      'button--block': isBlock,
      'button--line': isLine,
      'button--centered': isCentered,
      'button--square': isSquare,
      'button--rounded': isRounded,
      'button--icon-icon-tight': iconIsTight
    }"
    @click="onClick"
    @dblclick="onDoubleClick"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @focus="onFocus"
    @blur="onBlur"
    :force="forceLink"
    :disabled="isDisabled"
  >
    <div v-if="isLoading" class="button__loader">
      <Loader />
    </div>
    <div
      v-if="!!$slots['before'] || iconBefore"
      :class="{
        'button__before': true,
        'transparent': isLoading,
      }"
    >
      <slot name="before">
        <Icon :size="iconSize" :type="iconBefore" :isTight="iconIsTight" />
      </slot>
    </div>
    <div
      v-if="!!$slots['default'] || text"
      :class="{
        'button__content': true,
        'button__content--attach-counter': attachCounter,
        'transparent': isLoading
      }"
    >
      <T v-bind="typographyProps">
        <slot>
          {{ text }}
        </slot>
      </T>
      <div class="button__counter" v-if="counter">
        <T type="counter">
          {{ counter }}
        </T>
      </div>
    </div>
    <div
      v-if="!!$slots['after'] || iconAfter"
      :class="{
        'button__after': true,
        'transparent': isLoading,
      }"
    >
      <slot name="after">
        <Icon :size="iconSize" :type="iconAfter" :isTight="iconIsTight" />
      </slot>
    </div>
  </component>
</template>

<script>
import { arrayFrom } from '@/utils/objectHelpers';
import Icon from '@/components/icon/Icon.vue';
import T from '@/components/typography/T.vue';
import Loader from '@/components/loader/Loader.vue';

export default {
  name: 'Button',
  components: {
    Icon,
    T,
    Loader,
  },
  props: {
    text: String,
    size: String,
    form: String,
    state: [String, Array],

    typographyProps: {
      type: Object,
      default: () => ({
        type: 'button',
      }),
    },

    isLoading: Boolean,
    isDisabled: Boolean,

    isSquare: Boolean,
    isRounded: Boolean,
    isBlock: Boolean,
    isLine: Boolean,
    isCentered: {
      type: Boolean,
      default: true,
    },

    iconBefore: String,
    iconAfter: String,

    iconSize: {
      type: String,
      default: 'sm',
    },

    // If button
    type: {
      type: String,
      default: 'button',
    },

    // If link
    url: [String, Object],
    target: String,

    activeState: String,

    counter: Number,
    attachCounter: Boolean,

    forceLink: Boolean,
  },
  computed: {
    iconIsTight() {
      return (!!this.$slots.default || this.text) && (['sm', 'md', 'h36'].includes(this.size)) && (this.state === 'primary' || this.state === 'gray');
    },
    states() {
      return arrayFrom(this.state).filter(Boolean);
    },
    computedAttributes() {
      const isLink = !!this.url;

      if (!isLink) {
        return {
          is: 'button',
          type: this.type,
          form: this.form,
        };
      }

      const isExternalUrl = typeof this.url === 'string' && this.url.match(/^(ht|f)tp/);

      return {
        is: isExternalUrl ? 'a' : 'router-link',
        ...(!isExternalUrl ? {
          tag: 'button',
          type: this.type,
        } : {}),
        [isExternalUrl ? 'href' : 'to']: this.url,
        target: this.target,
        ...(!isExternalUrl && this.activeState ? {
          'active-class': `button--active-class--${this.activeState}`,
          exact: true,
        } : {}),
      };
    },
  },
  methods: {
    onClick(e) {
      if (this.isDisabled || this.isLoading) {
        return;
      }
      this.$emit('click', e);
    },
    onDoubleClick(e) {
      this.$emit('doubleClick', e);
    },
    onMouseEnter(e) {
      this.$emit('mouseEnter', e);
    },
    onMouseLeave(e) {
      this.$emit('mouseLeave', e);
    },
    onFocus(e) {
      this.$emit('focus', e);
    },
    onBlur(e) {
      this.$emit('blur', e);
    },
  },
};
</script>

