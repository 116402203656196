<template>
  <div
    class="field__wrapper"
    :class="{
      'field--error': !!error,
      'field--disabled': isDisabled,
      'field--with-after-input': !!$slots['after_input'],
      [`field--state--${state}`]: !!state,
    }"
  >
    <T
      v-if="label || $slots['label']"
      class="field__label"
      type="body-2"
      v-bind="typographyProps"
    >
    <slot name="label">{{label}}</slot>
    </T>
    <div
      :class="{
        'field': true,
        [`field--size--${size}`]: !!size,
        'field--readonly': isReadonly,
        'field--loading': isLoading,
        'field--focused': isFocused,
        'field--square': isSquare,
      }"
      :tabindex="isFocused ? -1 : 0"
      @focus="focus"
      @blur="blur"
      @mouseenter="mouseenter"
      @mouseleave="mouseleave"
    >
      <div
        v-if="!!$slots['before'] || iconBefore"
        class="field__before"
      >
        <slot name="before">
          <Icon :type="iconBefore" :size="iconSize" />
        </slot>
      </div>
      <div class="field__content">
        <input
          :autocomplete="autocomplete"
          :name="name"
          v-model="localValue"
          ref="input"
          v-bind="binds"
          v-on="events"
          v-if="component === 'input'"
        />
        <template v-else>
          <template v-if="isContentEditable">
            <div class="field__placeholder" v-if="placeholder && !localValue">{{placeholder}}</div>
            <div
              class="field__input"
              ref="input"
              contenteditable="true"
              @input="onContentEditableInput"
              :tabindex="0"
              @focus="focus"
              @blur="blur"
              @keydown.ctrl.enter.prevent="onSubmit"
            />
          </template>
          <textarea
            v-model="localValue"
            ref="input"
            v-bind="binds"
            v-on="events"
            v-else
            :rows="3"
          />
        </template>
      </div>
      <div
        v-if="$slots['after'] || iconAfter"
        class="field__after"
      >
        <slot name="after">
          <Icon :type="iconAfter" :size="iconSize" />
        </slot>
      </div>
    </div>
    <slot name="after_input"/>
    <T
      v-if="!!error"
      class="field__error"
      type="body-2"
      font="main"
      state="danger"
    >
      {{ error }}
    </T>
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon.vue';
import T from '@/components/typography/T.vue';
import { AbstractInputComponentMixin } from '@/mixins/AbstractFormFieldMixin';

export default {
  name: 'InputText',
  components: {
    Icon,
    T,
  },
  mixins: [AbstractInputComponentMixin],
  props: {
    size: String,
    type: {
      type: String,
      default: 'text',
    },
    autocomplete: String,
    typographyProps: {
      type: Object,
      default: () => ({}),
    },
    error: String,
    isSquare: Boolean,
    name: String,
    isContentEditable: Boolean,
  },
  computed: {
    component() {
      return this.type === 'textarea' ? 'textarea' : 'input';
    },
    binds() {
      return {
        class: 'field__input',
        type: this.type,
        tabIndex: -1,
        placeholder: this.placeholder,
        disabled: this.isDisabled,
        readonly: this.isReadonly,
      };
    },
    events() {
      return {
        focus: this.focus,
        blur: this.blur,
        change: ($event) => { this.$emit('change', $event.target.value); },
        input: ($event) => { this.$emit('input', $event.target.value); },
      };
    },
  },
  methods: {
    onSubmit(e) {
      console.log('onSubmit');
      this.isSubmitting = true;
      e.preventDefault();
      e.target.closest('form')?.querySelector('[type="submit"]')?.click();
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.isSubmitting = false;
      }, 250);
    },
    updateInputValue({ target }) {
      if (this.isReadonly || this.isDisabled) {
        return;
      }
      this.localValue = target.value;
    },
    onContentEditableInput(e) {
      if (this.isDisabled || this.isReadonly) {
        return;
      }
      this.localValue = e.target.innerText;
    },
    async sanitize() {
      requestAnimationFrame(() => {
        this.$refs.input.innerText = this.$refs.input.textContent;

        let range;
        let selection;

        if (document.createRange) {
          range = document.createRange();
          range.selectNodeContents(this.$refs.input);
          range.collapse(false);
          selection = window.getSelection();
          selection?.removeAllRanges();
          selection?.addRange(range);
        } else {
          range = document.body.createTextRange();
          range.moveToElementText(this.$refs.input);
          range.collapse(false);
          range.select();
        }
      });
    },
  },
  mounted() {
    this.unwatchContentEditable = this.$watch('value', (value) => {
      if ((this.isSubmitting || !this.isFocused) && this.isContentEditable) {
        this.$refs.input.innerText = value;
      }
    });
  },
  beforeDestroy() {
    this.unwatchContentEditable();
    clearTimeout(this.timeout);
  },
  watch: {
    isContentEditable: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$nextTick(() => {
            this.$refs.input.innerText = this.value;
            this.$refs.input.addEventListener('paste', this.sanitize);
          });
        } else {
          this.$nextTick(() => {
            this.$refs.input.removeEventListener('paste', this.sanitize);
          });
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.field {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  cursor: text;
  overflow: hidden;
  &__error {
    text-overflow: ellipsis;
    width: 100%;
    margin-top: rem(6px);
  }
  &--disabled {
    cursor: default !important;
    user-select: none;
    opacity: .5;
    &.field__label {
      opacity: .5;
    }
  }
  &--readonly {
    cursor: text;
    border-style: dotted;
  }
  &--centered {
    .input, .field__input {
      text-align: center;
    }
  }
  &__label {
    margin-bottom: rem(6px);
  }
  &__before {
    flex-shrink: 0;

    + * {
      margin-left: rem(10px);
    }
  }
  &__content {
    flex-grow: 1;

    display: flex;
    align-items: center;

    + * {
      margin-left: rem(10px);
    }
  }
  &__after {
    flex-shrink: 0;
    + * {
      margin-left: rem(10px);
    }
  }
  &__clear {
    flex-shrink: 0;
    + * {
      margin-left: rem(10px);
    }
  }
  &__input {
    width: 100%;
    @at-root textarea#{&} {
      resize: vertical;
    }
  }
  &__placeholder {
    position: absolute;
  }
  @each $size, $fs, $lh, $v, $h, $br in (
      ("sm", 14, 20, 6.5, 14, 5),
      ("md", 16, 24, 8, 16, 5),
  ) {
    &--size--#{$size} {
      padding: rem($v + 0px) rem($h + 0px);
      border-radius: rem($br + 0px);
      font-size: rem($fs + 0px);
      line-height: rem($lh + 0px);
      &.field--square {
        padding: rem($v + 0px);
      }
    }
  }
  &--state {
    &--primary {
      .field {
        color: var(--color-general-3);
        border: rem(1px) solid;
        border-color: var(--color-general-6);
        background: var(--color-background-1);
        transition: .25s color, .25s border-color, .25s background-color;
        &:hover {
          background: var(--color-general-6);
        }
      }
      .field--focused {
        border-color: var(--color-general-4);
      }
      .field__label {
        transition: .25s color;
      }
      .field__input::placeholder,
      .field__placeholder {
        color: var(--color-general-5);
      }
      .field__input:focus {
        color: var(--color-general-2);
      }
      &.field--disabled {
        opacity: 1;
        .field {
          color: var(--color-general-5);
        }
        .field__label {
          opacity: 1;
          color: var(--color-general-5);
        }
      }
      &.field--error {
        .field, .field__after {
          color: var(--color-red);
          border-color: var(--input-primary-border);
          background-color: var(--input-primary-background);
        }
        &>.typography {
          color: var(--color-red);
        }
      }
      .field__label {
        color: var(--color-general-3);
      }
    }
    &--primary-readonly {
      .field {
        color: var(--color-general-2);
        border: rem(1px) solid;
        border-color: var(--color-general-6);
        background: var(--color-background-1);
        &:hover {
          cursor: auto;
        }
      }
      &.field--error {
        .field {
          color: var(--color-red);
          border-color: var(--input-primary-border);
          background-color: var(--input-primary-background);
        }
      }
    }
    &--input-select-search {
      .field {
        color: var(--color-general-3);
        &__content {
          input {
            font-family: 'Nunito', sans-serif;
          }
        }
      }
      .field__input::placeholder,
      .field__placeholder {
        color: var(--color-general-5);
      }
      .field--disabled {}
      &.field--error {
        .field {
          color: var(--color-red);
          border-color: var(--input-primary-border);
          background-color: var(--input-primary-background);
        }
      }
    }
  }

  &--with-after-input {
    display: flex;
    flex-wrap: wrap;
    .field--error, .field__label {
      width: 100%;
    }
    @include mobile {
      flex-direction: column;
      .field ~ .button {
        order: 2;
        margin-top: 20px;
      }
    }
  }
  &__icon-copy {
    cursor: pointer;
  }
}
</style>
