import Vue from 'vue';
//import wrap from '@vue/web-component-wrapper';
import { i18n, i18nPlugin } from '@/i18n';
import { AxiosPlugin } from '@/api';
import Chat from '@/components/chat/ChatWrap';
import store from '@/store';
import FormatPlugin from '@/plugins/FormatPlugin';
import VueCompositionApi from '@vue/composition-api';
import vClickOutside from 'v-click-outside';




Vue.use(FormatPlugin);

Vue.use(VueCompositionApi);
Vue.use(vClickOutside);
Vue.use(AxiosPlugin, store);
Vue.use(i18nPlugin, store);
Vue.use(i18n);

//const CustomElement = wrap(Vue, Chat);
export default  Chat;

//window.customElements.define('solar-chat', CustomElement,{});
