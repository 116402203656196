<template>
  <div class="chat-loader">
    <Icon class="chat-loader__icon" type="loading" size="md"/>
    <div class="chat-loader__label">
      <T type="button">
        {{$t('chat.loading')}}
      </T>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon';
import T from '@/components/typography/T';

export default {
  name: 'ChatLoader',
  components: { T, Icon },
};
</script>

<style lang="scss" scoped >
  .chat-loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: var(--color-blue-1);

    &__icon {
      @keyframes chat-loader-rotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      animation: chat-loader-rotate 1s linear infinite;
    }

    &__label {
      margin-top: rem(8px);
    }
  }
</style>
