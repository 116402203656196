<template>
  <div ref="messagesList" class="chat-messages">
    <template v-for="message in messages">
      <template v-if="message.type === 'label'">
        <T
          :key="message.id"
          type="button"
          class="chat-messages__message chat-messages__message--label"
          v-html="message.label"
        />
      </template>
      <template v-else>
        <ChatMessage
          :key="message.id"
          :message="message.message"
          :class="{
            'chat-messages__message': true,
            'chat-messages__message--message': true,
          }"
          :is-same="message.isSame"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { useChat } from '@/hooks/useChat';
import {
  computed, nextTick, ref, watch,
} from '@vue/composition-api';
import { useI18n } from '@/hooks/useI18n';
import T from '@/components/typography/T';
import ChatMessage from '@/components/chat/message/ChatMessage';

export default {
  name: 'ChatMessages',
  components: { ChatMessage, T },
  setup() {
    const messagesList = ref(null);
    const { t } = useI18n();
    const {
      messages,
    } = useChat();

    const computedMessages = computed(() => [
      {
        type: 'label',
        label: t('chat.label.started'),
        id: 0,
      },
      ...messages.value.map((message, index, self) => ({
        type: message.system ? 'label' : 'message',
        id: message.id,
        ...(message.system ? {
          label: t(`chat.${message.message.replace('livechat.', '')}`, message.message_param || {}),
        } : {
          message,
          isSame: (
            (self[index - 1]?.operator === message.operator)
              && !message.system
              && !self[index - 1]?.system
          ),
        }),
      })),
    ]);

    const scrollToBottom = () => {
      nextTick(() => {
        messagesList.value.scrollTop = 1000000;
      });
    };

    watch(messagesList, scrollToBottom);
    watch(computedMessages, (newVal, oldVal) => {
      console.log(newVal, oldVal);
      if (newVal.length > oldVal.length) {
        scrollToBottom();
      }
    }, { deep: true });

    return {
      messagesList,
      messages: computedMessages,
    };
  },
};
</script>

<style lang="scss" scoped >
  .chat-messages {
    padding: rem(28px) rem(16px);
    overflow-y: auto;
    //display: flex;
    //flex-direction: column-reverse;

    &__message {
      $mR: &;

      &--label {
        background: var(--color-background-2);
        box-shadow: $box-shadow-primary;
        color: var(--color-general-2);

        border-radius: rem(5px);
        padding: rem(8px) rem(14px);

        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        margin-bottom: rem(20px);
      }

      &:not(&:last-child) {

        &.chat-message--operator + .chat-message--operator,
        &.chat-message--you + .chat-message--you {
          margin-bottom: rem(8px);
        }

        &.chat-message--operator + .chat-message--you,
        &.chat-message--you + .chat-message--operator,
        &.chat-messages__message--label + .chat-messages__message--message {
          margin-bottom: rem(20px);
        }
      }
    }
  }
</style>
