import { getRandomString } from '@/utils/stringHelpers';
import { arrayFrom } from '@/utils/objectHelpers';

export const AbstractInputComponentMixin = {
  props: {
    state: [String, Array],

    label: String,

    value: [Object, Array, String, Number, Boolean],
    placeholder: String,

    iconBefore: String,
    iconAfter: String,
    iconSize: {
      type: String,
      default: 'sm',
    },

    size: String,

    isReadonly: Boolean,
    isDisabled: Boolean,
    isLoading: Boolean,

    isClearable: Boolean,
    clearValue: [Object, Array, String, Number, Boolean],

    isCentered: Boolean,

    withField: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    fieldProps() {
      return {
        label: this.label,
        error: this.error,
        for: this.id,
        isCondensed: this.isCondensed,
      };
    },
    states() {
      return arrayFrom(this.state);
    },
  },
  data() {
    return {
      isFocused: false,
      isHovered: false,
      id: this.for || getRandomString(),
      localValue: this.value,
    };
  },
  watch: {
    value: {
      handler(value) {
        this.localValue = value;
      },
    },
    localValue(localValue) {
      this.updateValue(localValue);
    },
    for(id) {
      this.id = id || getRandomString();
    },
  },
  methods: {
    clear() {
      this.localValue = this.clearValue;
    },
    updateValue(value) {
      if (this.isDisabled || this.isReadonly) {
        return;
      }

      this.$emit('input', value);
    },
    focus(e) {
      if (this.isDisabled || this.isReadonly) {
        return;
      }

      this.isFocused = true;

      if (this.$refs && this.$refs.input) {
        this.$refs.input.focus();
      }

      this.$emit('focus', e);
    },
    blur(e) {
      if (e && typeof e === 'object' && e.type === 'click') {
        e.stopPropagation();
      }
      this.isHovered = false;
      this.isFocused = false;

      this.$emit('blur', e);
    },
    mouseenter(e) {
      this.isHovered = true;

      this.$emit('hover', e);
    },
    mouseleave(e) {
      this.isHovered = false;

      this.$emit('mouseleave', e);
    },
  },
};
