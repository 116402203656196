<template>
  <transition-group
    tag="div"
    class="chat-wrapper"
    name="chat-transition"
    :data-theme="theme"
    @after-leave="emitToggle"
  >
    <div
      v-if="isChatVisible"
      key="chat"
      class="chat-wrapper__chat chat"
    >
      <div class="chat__content">
        <ChatHeader class="chat__header"/>
        <div class="chat__body">
          <ChatLoader class="chat__loader" v-if="isChatLoading"/>
          <ChatPlaceholder class="chat__placeholder" v-else-if="!isChatExists"/>
          <ChatMessages v-else class="chat__messages"/>
        </div>
        <div class="chat__footer">
          <ChatForm class="chat__form"/>
        </div>
      </div>
    </div>
    <Button
      v-else
      key="trigger"
      class="chat-wrapper__trigger"
      is-square
      state="primary"
      icon-before="chat"
      icon-size="md"
      is-rounded
      size="huge"
      @click="showChat"
      ref="button"
    />
  </transition-group>
</template>

<script>
import { useChat } from '@/hooks/useChat';
import ChatForm from '@/components/chat/form/ChatForm';
import ChatLoader from '@/components/chat/loader/ChatLoader';
import ChatHeader from '@/components/chat/header/ChatHeader';
import ChatPlaceholder from '@/components/chat/placeholder/ChatPlaceholder';
import ChatMessages from '@/components/chat/messages/ChatMessages';
import Button from '@/components/button/Button';
import { computed, ref, watch } from '@vue/composition-api';
import store from '@/store';
import { i18n } from '@/i18n';

export default {
  store,
  i18n,
  provide: {
    store,
    i18n,
  },
  name: 'Chat',
  components: {
    Button,
    ChatMessages,
    ChatPlaceholder,
    ChatHeader,
    ChatLoader,
    ChatForm,
  },
  setup(props, { emit, root }) {
    const {
      isChatLoading,
      isChatExists,
      isChatVisible,
      toggleChat,
    } = useChat();

    watch(computed(() => store.getters['chat/openSession']), async (setSes) => {
      console.log('livechat set ses', setSes);

      if (setSes) {
        try {
          await store.dispatch('chat/setSession', setSes);
          isChatVisible.value = true;
        } catch (e) {
          console.log(e);
        }
      }
    });

    const showChat = () => {
      toggleChat(!isChatVisible.value);
    };

    const theme = ref('light');

    const emitToggle = () => {
      emit('toggle');
    };

    const elementTheme = window.document.getElementsByTagName('html')[0];

    const updateTheme = () => {
      const theme2 = elementTheme.dataset.theme;
      if (theme2) {
        theme.value = theme2;
      }
    };
    updateTheme();
    const observer = new MutationObserver((v) => {
      console.log('change theme', v);
      updateTheme();
    });

    observer.observe(elementTheme, { characterData: true, attributes: true });

    return {
      isChatVisible,
      isChatLoading,
      isChatExists,
      showChat,
      emitToggle,
      theme,
    };
  },
};
</script>

<style lang="scss" scoped>
  .chat-wrapper {
    display: flex;
    flex-direction: column;
    visibility: hidden;

    &__chat {
      margin-top: auto;
      width: rem(360px);
      height: rem(600px);
      max-height: 100%;
      flex-shrink: 0;
      visibility: visible;
      transition: .25s ease;
      transition-property: opacity, transform;
      z-index: 0;
    }

    &__trigger {
      visibility: visible;
      transition: .25s ease;
      transition-property: opacity, transform, bottom;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  .chat {
    border-radius: rem(5px);
    overflow: hidden;
    box-shadow: $box-shadow-primary;

    &__content {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &__header {
      flex-shrink: 0;
    }

    &__body {
      flex-grow: 1;
      height: 0;
      background: var(--color-background-4);

      display: flex;
      flex-direction: column;
    }

    &__loader {
      margin: auto;
    }

    &__placeholder {
      margin-top: rem(88px);
    }

    &__messages {
      flex-grow: 1;
    }

    &__footer {
      flex-shrink: 0;
      padding: rem(16px);
      background: var(--color-background-2);
      box-shadow: $box-shadow-primary;
      position: relative;
      z-index: 1;

      display: flex;
    }

    &__form {
      flex-grow: 1;
    }


  }

  .chat-transition {
    &-enter {
      transform: translateY(rem(20px));
      opacity: 0;
    }
    &-leave-to {
      transform: translateY(rem(20px));
      opacity: 0;
    }
  }


</style>
