import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from './locales/en.json';
import ru from './locales/ru.json';
import de from './locales/de.json';
import es from './locales/es.json';
import fr from './locales/fr.json';

const messages = {
  en, ru, de, es, fr,
};

Vue.use(VueI18n);

const getLang = () => {
  const supLang = ['en', 'ru', 'de', 'es', 'fr'];
  let lang = location.pathname.substr(0, 3).substr(1);
  if (!supLang.includes(lang)) {
    lang = navigator.languages[0] || navigator.language || 'en';
    lang = lang.substr(0, 2).toLocaleLowerCase();
  }
  if (!supLang.includes(lang)) lang = 'en';
  return lang;
};

export const i18n = new VueI18n({
  locale: getLang(),
  messages,
});

export const i18nPlugin = {
  install(vue, store) {
    store.$i18n = i18n;
  },
};
