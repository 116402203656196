<template>
  <div
    :class="{
      'icon': true,
      [`icon--size--${size}`]: !isTight
    }"
  >
    <component
      class="icon__source"
      :is="renderComponent"
      v-bind="attributes"
    />
  </div>
</template>

<script>

export default {
  name: 'Icon',
  props: {
    type: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'xs',
    },
    isTight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    attributes() {
      const opt = {};
     // opt.width = 'none';
     // opt.height = 'none';

      if (this.isTight) {
        switch (this.type) {
          case 'plus':
            opt.viewBox = '6 6 12 12';
            opt.class = 'icon__source--size-plus';
            break;
          case 'basket':
            opt.viewBox = '3 5 18 13';
            opt.class = 'icon__source--size-basket';
            break;
          case 'move':
            opt.viewBox = '0 0 18 18';
            opt.class = 'icon__source--size-move';
            break;
         /* case 'done':
            opt.viewBox = '0 0 18 18';
            opt.class = 'icon__source--size-move';
          case 'arrow-left': */
          case 'arrow-right':
            opt.viewBox = '4 7 16 10';
            opt.class = 'icon__source--size-arrow';
            break;
          case 'done':
            opt.viewBox = '3 3 12 12';
            opt.class = 'icon__source--size-done';
            break;
          case 'trash':
            opt.viewBox = '4 3 16 18';
            opt.class = 'icon__source--size-trash';
            break;
          case 'download':
            opt.viewBox = '6 6 12 12';
            opt.class = 'icon__source--size-download';
            break;
          default:
            // opt.viewBox = '6 6 12 12';
            opt.class = 'icon__source--size-unknown';
            break;
        }
      }
      return opt;
    },
    renderComponent() {
      return require(`@/assets/icons/${this.type}.svg`);
    },
  },
};
</script>

<style lang="scss" scoped >
  .icon {
    &--size {
      &--xxs {
        width: (14px);
        height: (14px);
      }
      &--xs {
        width: (20px);
        height: (20px);
      }
      &--sm {
        width: (24px);
        height: (24px);
      }
      &--md {
        width: (32px);
        height: (32px);
      }
      &--lg {
        width: (36px);
        height: (36px);
      }
      &--toggler {
        width: (28px);
        height: (14px);
      }
      &--big {
        width: (46px);
        height: (46px);
      }
    }
    &__source {
      pointer-events: none;
      fill: currentColor;
      display: block;
      height: 100%;
      width: 100%;

      &--size-unknown {
        width: rem(24px);
        height: rem(24px);
      }
      &--size-done {
        width: rem(16px);
        height: rem(12px);
      }
      &--size-plus {
        width: rem(10px);
        height: rem(10px);
      }
      &--size-basket {
        width: rem(18px);
        height: rem(13.5px);
      }
      &--size-move {
        width: rem(17.5px);
        height: rem(17.5px);
      }
      &--size-arrow {
        width: rem(15px);
        height: rem(9.5px);
      }
      &--size-trash {
        width: rem(15px);
        height: rem(18px);
      }
      &--size-download {
        width: (12px);
        height: (12px);
      }
    }
  }
</style>
