import Vue from 'vue';
import Vuex from 'vuex';


import app from '@/store/modules/app';
import session from '@/store/modules/session';
import chat, { ChatStorePlugin } from '@/store/modules/chat';


Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    session,
    chat,
  },
  plugins: [
    ChatStorePlugin,
  ],
});

export default store;
