var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'chat-message': true,
    'chat-message': true,
    'chat-message--you': !_vm.message.operator,
    'chat-message--operator': _vm.message.operator,
  }},[_c('div',{staticClass:"chat-message__body"},[(!_vm.isSame)?_c('div',{staticClass:"chat-message__header"},[_c('T',{staticClass:"chat-message__sender",attrs:{"type":"button"}},[_vm._v(" "+_vm._s(_vm.message.sender)+" ")]),_c('T',{staticClass:"chat-message__date",attrs:{"type":"caption"}},[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.message.created_at.replace(/-/g, '/'),{withHours: true, withMinutes: true}))+" ")])],1):_vm._e(),_c('div',{staticClass:"chat-message__content"},[(_vm.isFile)?_c(_vm.file ? 'a' : 'div',{tag:"component",class:{
          'chat-message__file': true,
          'chat-message__file--error': _vm.errorWhileDownloadingFile,
        },attrs:{"href":_vm.file ? _vm.file.url : undefined,"target":_vm.file ? '_blank' : undefined}},[_c('span',{class:{
            'chat-message__file-icon': true,
            'chat-message__file-icon--loading': _vm.isLoadingFile,
          }},[_c('Icon',{attrs:{"type":_vm.isLoadingFile ? 'loader' : (_vm.errorWhileDownloadingFile ? 'close' : 'file'),"size":"sm"}})],1),(_vm.errorWhileDownloadingFile)?_c('T',{staticClass:"chat-message__file-error",attrs:{"type":"caption"}},[_vm._v(" "+_vm._s(_vm.t('chat.file.error'))+" ")]):(!_vm.isLoadingFile)?_c('span',{staticClass:"chat-message__file-info"},[_c('T',{staticClass:"chat-message__file-name",attrs:{"type":"body-2-tall"}},[_vm._v(" "+_vm._s(_vm.file && _vm.file.name)+" ")]),_c('T',{staticClass:"chat-message__file-size",attrs:{"type":"caption"}},[_vm._v(" "+_vm._s(_vm.file && _vm.file.size)+" ")])],1):_vm._e()],1):_c('T',{staticClass:"chat-message__message",attrs:{"type":"body-1","state":"bright"},domProps:{"innerHTML":_vm._s(_vm.unescape(_vm.escape(_vm.message.message).replace(/%0A/g, '<br/>')))}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }