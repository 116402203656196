import { getRandomString } from '@/utils/stringHelpers';

import countries from '@/store/modules/variables/countries.json';
import timezone from '@/store/modules/variables/timezone.json';

import { version } from '../../../package.json';


export default {
  namespaced: true,
  state: {
    version,
    countries,
    timezone,
    appInitialized: false,
    appLoading: true,
    layoutLoading: false,
    locale: 'en',
    redirectUrl: null,
    modals: [],
    toasts: [],
    serverError: false,
    error500: false,
    error404: false,
  },
  mutations: {
    setLocale(state, locale) {
      state.locale = locale;
    },
    setRedirectUrl(state, url) {
      state.redirectUrl = url;
    },
    removeRedirectUrl(state) {
      state.redirectUrl = null;
    },
    setAppLoading(state, appLoading) {
      state.appLoading = appLoading;
    },
    setLayoutLoading(state, layoutLoading) {
      state.layoutLoading = layoutLoading;
    },
    setAppInitializedStatus(state, status) {
      state.appInitialized = status;
    },
    deleteToast(state, id) {
      state.toasts = state.toasts.filter((toast) => toast.id !== id);
    },
    deleteModal(state, id) {
      state.modals = state.modals.filter((modal) => modal.id !== id);
    },
    deleteAllModals(state) {
      state.modals = [];
    },
    addModal(state, modal) {
      state.modals.push(modal);
    },
    deleteAllToasts(state) {
      state.toasts = [];
    },
    addToast(state, toast) {
      state.toasts.push(toast);
    },
    setServerError(state, isError) {
      state.serverError = isError;
    },
    set500Error(state, isError) {
      state.error500 = isError;
    },
    set404Error(state, isError) {
      state.error404 = isError;
    },
  },
  actions: {
    saveRedirectUrl({ commit }, url) {
      commit('setRedirectUrl', url);
    },
    clearRedirectUrl({ commit }) {
      commit('removeRedirectUrl');
    },
    toggleAppLoading({ commit }, appLoading) {
      commit('setAppLoading', appLoading);
    },
    toggleLayoutLoading({ commit }, layoutLoading) {
      commit('setLayoutLoading', layoutLoading);
    },
    toggleAppInitializedStatus({ commit }, status) {
      commit('setAppInitializedStatus', status);
    },
    removeToast({ commit }, id) {
      commit('deleteToast', id);
    },
    removeModal({ commit }, id) {
      commit('deleteModal', id);
    },
    removeAllModals({ commit }) {
      commit('deleteAllModals');
    },
    throwModal({ commit }, modal) {
      modal.id = getRandomString();
      commit('addModal', modal);
      return modal.id;
    },
    removeAllToasts({ commit }) {
      commit('deleteAllToasts');
    },
    throwToast({ commit }, toast) {
      toast.id = getRandomString();
      commit('addToast', toast);
      return toast.id;
    },
    removeServerError({ commit }) {
      commit('setServerError', false);
    },
    throwServerError({ commit, rootState }, isError = true) {
      if (rootState.session.authorized) {
        commit('setServerError', isError);
      }
    },
    throw500Error({ commit }) {
      commit('set500Error', true);
    },
    remove500Error({ commit }) {
      commit('set500Error', false);
    },
    throw404Error({ commit }) {
      commit('set404Error', true);
    },
    remove404Error({ commit }) {
      commit('set404Error', false);
    },
  },
  getters: {
    appLocale: (state) => state.locale,
    appRedirectUrl: (state) => state.redirectUrl,
    isAppLoading: (state) => state.appLoading,
    isLayoutLoading: (state) => state.layoutLoading,
    isAppInitialized: (state) => state.appInitialized,
    modals: (state) => state.modals,
    toasts: (state) => state.toasts,
    timezones: (state) => state.timezone,
    countries: (state) => state.countries,
    serverError: (state) => state.serverError,
    error500: (state) => state.error500,
    error404: (state) => state.error404,
    version: (state) => state.version,
  },
};
