<template>
  <Chat @toggle="calculateChatPosition" ref="chat" class="base-layout__chat"/>
</template>

<script>
import Chat from './Chat';

export default {
  name: 'ChatWrap',
  components: { Chat },
  methods: {
    calculateChatPosition() {
      const chatButton = this.$refs?.chat?.$refs?.button?.$el;

      if (!chatButton) {
        return;
      }
      let footerBounds = window.document.querySelector('footer .center:last-child');
      if (!footerBounds) {
        footerBounds = window.document.getElementsByTagName('footer');

        if (footerBounds.length === 0) {
          footerBounds = window.document.getElementById('footer');
        } else {
          footerBounds = footerBounds[0];
        }

      }


      const { innerHeight } = window;

      let bottom = footerBounds ? (innerHeight - footerBounds.getBoundingClientRect().top) : 0;
      if (bottom < 0) bottom = 0;



      chatButton.style.setProperty('bottom', `${bottom}px`);
    },
    initChatWatcher() {
      this.calculateChatPosition();
      window.addEventListener('scroll', this.calculateChatPosition);
      window.addEventListener('resize', this.calculateChatPosition);
      window.addEventListener('orientationchange', this.calculateChatPosition);
      clearInterval(this.chatInterval);
      this.chatInterval = setInterval(this.calculateChatPosition, 250);
    },
    deInitChatWatcher() {
      window.removeEventListener('scroll', this.calculateChatPosition);
      window.removeEventListener('resize', this.calculateChatPosition);
      window.removeEventListener('orientationchange', this.calculateChatPosition);
      clearInterval(this.chatInterval);
    },
  },
  mounted() {
    this.initChatWatcher();
  },
  beforeDestroy() {
    this.deInitChatWatcher();
  },
  created() {
    window.document.body.append((() => { const e = document.createElement('div'); e.id = 'tooltipLayer'; e.classList.add('app__tooltip-layer'); return e; })());
  },
};
</script>
