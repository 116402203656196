<template>
  <div class="chat-agent" v-if="isAgentAssigned" >
    <div
      :class="{
        'chat-agent__avatar': true,
        'chat-agent__avatar--loaded': errorAvatar !== null,
      }"
      :style="{backgroundImage: errorAvatar !== null ? `url(${
        errorAvatar ? defaultAvatar : agent.avatar
      })` : undefined}"
    >
      <img
        :alt="agent.name"
        @load="errorAvatar = false"
        @error="errorAvatar = true"
        @loadstart="errorAvatar = null"
        :src="agent.avatar"
      />
    </div>
    <div class="chat-agent__info">
      <div class="chat-agent__header">
        <T type="heading-2" class="chat-agent__title">
          {{t('chat.support')}}
        </T>
        <div
          v-if="!isRated"
          :class="{
            'chat-agent__rates': true,
            'chat-agent__rates--rating': isRating,
          }"
        >
          <div class="chat-agent__rate" @click="commitRating(true)">
            <Icon size="sm" type="like"/>
          </div>
          <div class="chat-agent__rate" @click="commitRating(false)">
            <Icon size="sm" type="dislike"/>
          </div>
        </div>
      </div>
      <div class="chat-agent__footer">
        <T type="button" class="chat-agent__name">
          {{agent.name}}
        </T>
        <T type="caption" class="chat-agent__typing" v-if="agent.isTyping">
          {{t('chat.typing')}}
        </T>
      </div>
    </div>
  </div>
</template>

<script>
import { useChat } from '@/hooks/useChat';
import Icon from '@/components/icon/Icon';
import { useI18n } from '@/hooks/useI18n';
import T from '@/components/typography/T';
import { ref } from '@vue/composition-api';

export default {
  name: 'ChatAgent',
  components: { T, Icon },
  setup() {
    const { t } = useI18n();
    const {
      isAgentAssigned, agent, rate, isRated,
    } = useChat();

    const isRating = ref(false);

    const commitRating = async (value) => {
      if (isRating.value) {
        return;
      }
      isRating.value = true;
      await rate(value);
      isRating.value = false;
    };

    const errorAvatar = ref(null);

    const defaultAvatar = `https://member.${window.location.hostname}/img/avatars/default.png`;

    return {
      isAgentAssigned,
      agent,
      t,
      isRated,
      commitRating,
      isRating,
      errorAvatar,
      defaultAvatar,
    };
  },
};
</script>

<style lang="scss" scoped>
  .chat-agent {
    display: flex;
    align-items: center;
    &__avatar {
      height: rem(50px);
      width: rem(50px);
      border-radius: 100%;
      background: no-repeat center;
      background-size: cover;

      opacity: 0;

      transition: opacity .5s ease;

      &--loaded {
        opacity: 1;
      }

      img {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
      }
    }
    &__info {
      margin-left: rem(16px);
      flex-grow: 1;
    }
    &__header {
      display: flex;
      align-items: center;
    }
    &__title {
      color: var(--color-general-2);
    }
    &__rates {
      margin-left: rem(8px);
      display: flex;
      align-items: center;

      transition: opacity .5s ease;

      &--rating {
        opacity: .6;

        .chat-agent__rate {
          cursor: default;
          pointer-events: none;
        }
      }
    }
    &__rate {
      cursor: pointer;

      transition: .25s ease;
      transition-property: filter, opacity;

      &:not(:hover):not(:focus) {
        filter: grayscale(100%);
        opacity: .5;
      }
    }
    &__footer {
      display: flex;
      align-items: center;
      margin-top: rem(2px);
    }
    &__name {
      color: var(--color-general-2);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      flex-grow: 1;
      width: 0;
    }
    &__typing {
      color: var(--color-general-5);
      margin-left: rem(12px);
      white-space: nowrap;
    }
  }
</style>
