import { inject } from '@vue/composition-api';

export const useI18n = () => {
  const i18n = inject('i18n');

  const t = i18n.t.bind(i18n);

  return {
    t,
  };
};
