var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'tooltip__wrapper': true,
    'tooltip__wrapper--inline': _vm.inline
  }},[_c('div',{ref:"tooltip",class:( _obj = {
      'tooltip': true
    }, _obj[("tooltip--" + _vm.position)] = true, _obj['tooltip--active'] =  _vm.tooltipActive, _obj[("tooltip--size--" + _vm.size)] = _vm.size, _obj ),style:(_vm.computedTooltipStyles)},[_vm._t("content",[_c('T',{attrs:{"state":"secondary","type":"body-1"}},[_vm._v(" "+_vm._s(_vm.content)+" ")])])],2),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onOutsideClick),expression:"onOutsideClick"}],ref:"trigger",staticClass:"tooltip__trigger",on:{"click":_vm.onClick,"mouseenter":_vm.onMouseEnter,"mouseleave":_vm.onMouseLeave}},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }