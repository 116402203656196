import axios from 'axios';
import {
  requestOnResolve, requestOnReject, responseOnResolve, responseOnReject,
} from '@/api/interceptors';

const axiosInstance = axios.create({
  baseURL: window.appBuildConfig.VUE_APP_API_HOST || process.env.VUE_APP_API_HOST,
  timeout: 15000,
});
axiosInstance.interceptors.request.use(requestOnResolve, requestOnReject);
axiosInstance.interceptors.response.use(responseOnResolve, responseOnReject);
axiosInstance.defaults.withCredentials = false;

export const AxiosPlugin = {
  install(Vue, store) {
    Vue.prototype.$axios = axiosInstance;
    store.$axios = axiosInstance;
  },
};
