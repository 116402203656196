var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('component',_vm._b({class:Object.assign(( _obj = {
    'button': true
  }, _obj[("button--size--" + _vm.size)] = !!_vm.size, _obj ),
    _vm.states.reduce(function (acc, stateVal) {
      var _obj;

      return (Object.assign({}, acc,
      ( _obj = {}, _obj[("button--state--" + stateVal)] = true, _obj )));
}, {}),
    {'button--disabled': _vm.isDisabled},
    {'button--block': _vm.isBlock},
    {'button--line': _vm.isLine},
    {'button--centered': _vm.isCentered},
    {'button--square': _vm.isSquare},
    {'button--rounded': _vm.isRounded},
    {'button--icon-icon-tight': _vm.iconIsTight}),attrs:{"force":_vm.forceLink,"disabled":_vm.isDisabled},on:{"click":_vm.onClick,"dblclick":_vm.onDoubleClick,"mouseenter":_vm.onMouseEnter,"mouseleave":_vm.onMouseLeave,"focus":_vm.onFocus,"blur":_vm.onBlur}},'component',_vm.computedAttributes,false),[(_vm.isLoading)?_c('div',{staticClass:"button__loader"},[_c('Loader')],1):_vm._e(),(!!_vm.$slots['before'] || _vm.iconBefore)?_c('div',{class:{
      'button__before': true,
      'transparent': _vm.isLoading,
    }},[_vm._t("before",[_c('Icon',{attrs:{"size":_vm.iconSize,"type":_vm.iconBefore,"isTight":_vm.iconIsTight}})])],2):_vm._e(),(!!_vm.$slots['default'] || _vm.text)?_c('div',{class:{
      'button__content': true,
      'button__content--attach-counter': _vm.attachCounter,
      'transparent': _vm.isLoading
    }},[_c('T',_vm._b({},'T',_vm.typographyProps,false),[_vm._t("default",[_vm._v(" "+_vm._s(_vm.text)+" ")])],2),(_vm.counter)?_c('div',{staticClass:"button__counter"},[_c('T',{attrs:{"type":"counter"}},[_vm._v(" "+_vm._s(_vm.counter)+" ")])],1):_vm._e()],1):_vm._e(),(!!_vm.$slots['after'] || _vm.iconAfter)?_c('div',{class:{
      'button__after': true,
      'transparent': _vm.isLoading,
    }},[_vm._t("after",[_c('Icon',{attrs:{"size":_vm.iconSize,"type":_vm.iconAfter,"isTight":_vm.iconIsTight}})])],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }