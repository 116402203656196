<template>
  <div class="chat-controls">
    <Tooltip
      size="sm"
      position="top"
      trigger="hover"
    >
      <template #content>
        <T state="secondary" type="body-1">
          {{ isVolumeOn ? $t('chat.mute') : $t('chat.unmute') }}
        </T>
      </template>
      <Button
        @click="toggleVolume"
        :class="{
          'chat-controls__control': true,
          'chat-controls__control--active': isVolumeOn,
        }"
        :icon-before="isVolumeOn ? 'volume-on' : 'volume-off'"
        state="default"
        is-square
      />
    </Tooltip>
    <Tooltip
      size="sm"
      position="top"
      trigger="hover"
      v-if="isChatExists"
    >
      <template #content>
        <T state="secondary" type="body-1">
          {{ $t('modal.close') }}
        </T>
      </template>
      <Button
        @click="closeChat"
        :class="{
          'chat-controls__control': true,
          'chat-controls__control--active': isVolumeOn,
        }"
        icon-before="close"
        state="default"
        is-square
        :is-loading="isClosingChat"
      />
    </Tooltip>
    <Tooltip
      size="sm"
      position="top"
      trigger="hover"
    >
      <template #content>
        <T state="secondary" type="body-1">
          {{ $t('chat.minimize') }}
        </T>
      </template>
      <Button
        @click="toggleChat"
        :class="{
          'chat-controls__control': true,
          'chat-controls__control--toggle': true,
          'chat-controls__control--active': isChatVisible,
        }"
        icon-before="down"
        state="default"
        is-square
      />
    </Tooltip>
  </div>
</template>

<script>
import { useChat } from '@/hooks/useChat';
import Button from '@/components/button/Button';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import T from '@/components/typography/T.vue';

export default {
  name: 'ChatControls',
  components: {
    T,
    Button,
    Tooltip,
  },
  setup() {
    const {
      isChatExists,

      isChatVisible,
      toggleChat,

      isVolumeOn,
      toggleVolume,

      isClosingChat,
      closeChat,
    } = useChat();

    return {
      isChatExists,

      isChatVisible,
      toggleChat,

      isVolumeOn,
      toggleVolume,

      isClosingChat,
      closeChat,
    };
  },
};
</script>

<style lang="scss" scoped>
  .chat-controls {
    display: flex;
    align-items: center;

    &__control {
      &:not(:first-child) {
        margin-left: rem(4px);
      }

      &--toggle {
        &:not(.chat-controls__control--active) {
          transform: rotate(180deg);
        }
      }
    }
  }
</style>
