import Cookie from 'js-cookie';

export const getFromStorage = (item) => localStorage.getItem(item);
export const getFromSessionStorage = (item) => sessionStorage.getItem(item);

export const getFromCookie = (name) => Cookie.get(name);

export const setToSessionCookie = (name, val) => Cookie.set(name, val, { path: '/' });

export const removeFromCookie = (name) => {
  Cookie.remove(name, { path: '/' });
};

export const setToStorage = (item, value) => {
  localStorage.setItem(item, value);
};

export const setToSessionStorage = (item, value) => {
  sessionStorage.setItem(item, value);
};

export const removeFromStorage = (item) => {
  localStorage.removeItem(item);
};
export const removeFromSessionStorage = (item) => {
  sessionStorage.removeItem(item);
};

export const clearStorage = () => {
  localStorage.clear();
};
export const clearSessionStorage = () => {
  sessionStorage.clear();
};
